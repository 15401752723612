<template>
    <div>
        <label
            :for="`select-${_uid}`"
            :class="{
                'sr-only': !label || hideLabel
            }"
            class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        >
            <span v-if="label">{{ label }}</span>
            <span v-else>Make a selection</span>
        </label>
        <div class="relative w-full">
            <select
                :id="`select-${_uid}`"
                @change="selectChange"
                :class="{
                    'py-1': slim,
                    'py-2': !slim
                }"
                class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline m-0"
            >
                <option value="">{{ defaultSelectOptionLabel }}</option>
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option.value"
                    >{{ option.label }}</option
                >
            </select>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 text-primary"
            >
                <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import eventHub from "@/event-hub";

export default {
    name: "mg-select",
    props: {
        defaultSelectOptionLabel: {
            type: String,
            default: "select"
        },
        id: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        "hide-label": Boolean,
        slim: Boolean,
        options: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    methods: {
        selectChange($event) {
            const payload = {
                id: this.id ? this.id : `Select ${this._uid}`,
                value: $event.target.value
            };

            // TODO
            // dont use event hub. emit with `this.$emit` to parent instead.
            // eventHub.$emit will broadcast to whole app.
            // this component is generic and can display multiple times in a given ui.
            eventHub.$emit("select-selection", payload);
        }
    }
};
</script>
