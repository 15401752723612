<template>
    <transition-group name="page-fade" tag="div" class="message-list">
        <div v-for="message in messageData" :key="message.id">
            <article
                aria-label="message by"
                class="flex flex-col md:flex-row media pb-6"
            >
                <figure v-if="enableThumbnail" class="media-left">
                    <p class="image is-64x64">
                        <img
                            class="is-rounded"
                            src="https://bulma.io/images/placeholders/128x128.png"
                            alt=""
                        />
                    </p>
                </figure>

                <div class="media-content w-full">
                    <div class="content">
                        <div class="is-marginless flex-vertical">
                            <div class="message-list__name">
                                <div
                                    v-if="disableNameLink"
                                    :class="{
                                        'opacity-75': dimMeta
                                    }"
                                    class="font-bold leading-none truncate"
                                >
                                    {{ message.name }}
                                </div>

                                <router-link
                                    v-else
                                    class="font-bold"
                                    :to="
                                        `/attendees/attendee-profile/${message.id}`
                                    "
                                >
                                    {{ message.name }}
                                </router-link>
                            </div>

                            <span
                                v-html="message.message"
                                class="break-links my-1"
                            ></span>

                            <span
                                :class="{
                                    'opacity-75': dimMeta
                                }"
                                class="text-xs message-list__meta"
                            >
                                {{
                                    MgFormatISODateTime(
                                        message.time,
                                        "date-time"
                                    )
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </transition-group>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "message-list",
    props: {
        dimMeta: {
            type: Boolean
        },
        disableNameLink: {
            type: Boolean
        },
        enableThumbnail: {
            type: Boolean
        },
        messageData: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    computed: {
        ...mapState(["route"])
    },

    methods: {
        handleDummyData() {
            this.messageData = [
                {
                    name: "Timmy Hansen",
                    message:
                        "Good question - I am still trying to figure that out!",
                    time: "2020-06-23T03:52:04.113Z"
                },
                {
                    name: "Philip Mckinney",
                    message:
                        "Do greatest at in learning steepest. Breakfast extremity suffering one who all otherwise suspected.",
                    time: "2020-06-23T03:52:04.113Z"
                },
                {
                    name: "Roman Shelton",
                    message:
                        "Advanced extended doubtful he he blessing together.",
                    time: "2020-06-23T03:52:04.113Z"
                }
            ];
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../styles/views/messagecenter";
</style>
