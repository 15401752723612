<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <title>hand</title>
        <path
            d="M17 16c0 2.209-1.791 4-4 4v0h-6c0 0 0 0-0 0-2.209 0-4-1.791-4-4 0-0.004 0-0.007 0-0.011v0.001-11.99c0-0.552 0.448-1 1-1v0c0.552 0 1 0.448 1 1v0 6h1v-8c0-0.552 0.448-1 1-1v0c0.552 0 1 0.448 1 1v0 8h1v-9c0-0.552 0.448-1 1-1s1 0.448 1 1v0 9h1v-8c0-0.552 0.448-1 1-1v0c0.552 0 1 0.448 1 1v0 13h1v-6c0-0.552 0.448-1 1-1v0h1v8z"
        ></path>
    </svg>
</template>

<script>
export default {
    name: "svg-hand"
};
</script>
