<template>
    <div
        key="data-loaded"
        :class="{
            'mg-control-panel-wrapper--title-active': meetingHasTopic,
            'mg-control-panel-wrapper--active': isUiActive
        }"
        class="meeting-container mg-control-panel-wrapper min-h-screen flex flex-col flex-center items-center"
        ref="mg-control-panel-wrapper"
    >
        <div
            ref="bg-layer"
            aria-hidden="true"
            class="fixed top-0 left-0 w-screen h-screen bg-brand"
        ></div>

        <audio id="GrahamBell"></audio>

        <div
            v-if="!meetingHasConnection"
            key="meetingErrorNotice"
            class="flex items-center justify-center h-screen w-screen fixed z-10"
        >
            <div class="notification bg-red-300 max-w-md shadow">
                <span v-if="errorMessage" key="errorMessage">{{
                    errorMessage
                }}</span>
                <div v-else key="errorMessageGeneric">
                    <b>Sorry</b>, there was an error establishing a connection
                    to the meeting.
                </div>
            </div>
        </div>

        <transition name="page-fade" mode="out-in">
            <div
                v-if="gridViewIsActive"
                key="grid-view"
                class="flex flex-wrap justify-center items-center w-full z-10 relative flex-1"
            >
                <div
                    :class="{
                        'grid-wrapper-max-w':
                            Object.keys(attendeesForDisplay).length < 5
                    }"
                    class="grid-wrapper w-full mx-auto"
                >
                    <div
                        class="p-6 w-full flex flex-wrap justify-center items-center"
                    >
                        <div
                            v-for="(value,
                            attendeeId,
                            index) in attendeesForDisplay"
                            :key="`${attendeeId}-${index}`"
                            :class="{
                                'lg:w-1/4':
                                    4 < Object.keys(attendeesForDisplay).length,
                                'lg:w-full':
                                    1 ===
                                    Object.keys(attendeesForDisplay).length
                            }"
                            class="flex w-full relative p-2 md:w-1/2"
                        >
                            <div
                                class="video-thumbnail--grid-item-inner relative video-thumbnail mg-filter"
                            >
                                <div
                                    v-addvideoel
                                    :data-attendee-id="attendeeId"
                                    class="h-full w-full top-0 left-0 absolute"
                                ></div>

                                <div
                                    class="absolute top-0 left-0 ml-2 mt-2 text-lg py-1 px-3 rounded-full mg-filter text-white flex"
                                >
                                    <div
                                        :class="{
                                            'text-red-500': value.muted
                                        }"
                                        class="mr-3 leading-none flex items-center justify-center"
                                    >
                                        <svg-mic-off
                                            v-if="value.muted"
                                            aria-hidden="true"
                                        />
                                        <svg-mic v-else aria-hidden="true" />
                                    </div>

                                    <div
                                        :class="{
                                            'mr-3': !value.muted
                                        }"
                                        class="leading-none flex items-center justify-center"
                                    >
                                        <svgConnection
                                            v-if="75 <= value.signalStrength"
                                            aria-hidden="true"
                                        />
                                        <svgConnection2
                                            v-else-if="
                                                50 <= value.signalStrength
                                            "
                                            aria-hidden="true"
                                        />
                                        <svgConnection3
                                            v-else-if="
                                                25 <= value.signalStrength
                                            "
                                            aria-hidden="true"
                                        />
                                        <svgConnection4
                                            v-else
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div
                                        v-if="!value.muted"
                                        class="leading-none flex items-center justify-center"
                                    >
                                        <svgVolumeHigh
                                            v-if="75 <= value.volume"
                                            aria-hidden="true"
                                        />
                                        <svgVolumeMedium
                                            v-else-if="50 <= value.volume"
                                            aria-hidden="true"
                                        />
                                        <svgVolumeLow
                                            v-else-if="25 < value.volume"
                                            aria-hidden="true"
                                        />
                                        <svgVolumeMute
                                            v-else
                                            class="opacity-25"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>

                                <div
                                    class="text-right text-white text-xs py-1 px-3 rounded-full tracking-wide absolute bottom-0 right-0 mr-2 mb-2 mg-filter"
                                >
                                    <span>{{ formatName(value.info) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                key="not-grid-view"
                class="relative top-0 left-0 w-full h-screen lg:flex"
            >
                <div
                    class="w-full relative flex-1 flex items-center overflow-hidden"
                >
                    <div class="p-4 w-full">
                        <div class="videoWrapper mg-filter shadow">
                            <div
                                v-addspeakerel
                                ref="mg-speaker-element"
                                class="h-full w-full top-0 left-0 absolute"
                            ></div>

                            <div
                                v-if="currentSpeakerData"
                                class="absolute top-0 left-0 ml-2 mt-2 text-lg py-1 px-3 rounded-full mg-filter text-white flex"
                            >
                                <div
                                    :class="{
                                        'text-red-500': currentSpeakerData.muted
                                    }"
                                    class="mr-3 leading-none flex items-center justify-center"
                                >
                                    <svg-mic-off
                                        v-if="currentSpeakerData.muted"
                                        aria-hidden="true"
                                    />
                                    <svg-mic v-else aria-hidden="true" />
                                </div>

                                <div
                                    :class="{
                                        'mr-3': !currentSpeakerData.muted
                                    }"
                                    class="leading-none flex items-center justify-center"
                                >
                                    <svgConnection
                                        v-if="
                                            75 <=
                                                currentSpeakerData.signalStrength
                                        "
                                        aria-hidden="true"
                                    />
                                    <svgConnection2
                                        v-else-if="
                                            50 <=
                                                currentSpeakerData.signalStrength
                                        "
                                        aria-hidden="true"
                                    />
                                    <svgConnection3
                                        v-else-if="
                                            25 <=
                                                currentSpeakerData.signalStrength
                                        "
                                        aria-hidden="true"
                                    />
                                    <svgConnection4 v-else aria-hidden="true" />
                                </div>

                                <div
                                    v-if="!currentSpeakerData.muted"
                                    class="leading-none flex items-center justify-center"
                                >
                                    <svgVolumeHigh
                                        v-if="66 <= currentSpeakerData.volume"
                                        aria-hidden="true"
                                    />
                                    <svgVolumeMedium
                                        v-else-if="
                                            33 <= currentSpeakerData.volume
                                        "
                                        aria-hidden="true"
                                    />
                                    <svgVolumeLow
                                        v-else-if="
                                            15 < currentSpeakerData.volume
                                        "
                                        aria-hidden="true"
                                    />
                                    <svgVolumeMute
                                        v-else
                                        class="opacity-25"
                                        aria-hidden="true"
                                    />
                                </div>
                            </div>

                            <div
                                v-if="currentSpeakerData"
                                class="text-white text-xs py-1 px-3 rounded-full tracking-wide absolute bottom-0 right-0 mr-2 mb-2 mg-filter"
                            >
                                <span>{{
                                    formatName(currentSpeakerData.info)
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Speaker view Thumbnails -->
                <div
                    class="meeting-container__thumbnails-wrapper p-4 w-full lg:w-1/3 overflow-y-scroll overscroll-contain flex items-start"
                >
                    <div class="w-full flex flex-wrap m-auto">
                        <!-- <div
                            v-for="i in 10"
                            :key="`test-tile-${i}`"
                            class="w-full md:w-1/2"
                        >
                            <div
                                class="bg-accent flex items-end justify-end mg-filter relative video-thumbnail video-thumbnail--grid-item-inner"
                            >
                                Test tile.
                            </div>
                        </div> -->

                        <div
                            v-for="(value,
                            attendeeId,
                            index) in attendeesForDisplay"
                            :key="`${index}-${attendeeId}`"
                            class="w-full md:w-1/2 lg:w-full"
                            :class="{
                                'xl:w-1/2':
                                    Object.keys(attendeesForDisplay).length > 7
                            }"
                        >
                            <div
                                class="flex items-end justify-end mg-filter relative video-thumbnail video-thumbnail--grid-item-inner"
                            >
                                <div
                                    v-if="currentSpeaker === attendeeId"
                                    :data-attendee-id="attendeeId"
                                    key="current-speaker"
                                    class="h-full w-full top-0 left-0 absolute bg-brand flex items-center justify-center mg-filter"
                                >
                                    <span class="block text-white"
                                        >Current speaker</span
                                    >
                                </div>
                                <div
                                    v-else
                                    key="not-current-speaker"
                                    v-addvideoel
                                    :data-attendee-id="attendeeId"
                                    class="h-full w-full top-0 left-0 absolute"
                                ></div>

                                <div
                                    class="absolute top-0 left-0 ml-2 mt-2 text-lg py-1 px-3 rounded-full mg-filter text-white flex"
                                >
                                    <div
                                        :class="{
                                            'text-red-500': value.muted
                                        }"
                                        class="mr-3 leading-none flex items-center justify-center"
                                    >
                                        <svg-mic-off
                                            v-if="value.muted"
                                            aria-hidden="true"
                                        />
                                        <svg-mic v-else aria-hidden="true" />
                                    </div>

                                    <div
                                        :class="{
                                            'mr-3': !value.muted
                                        }"
                                        class="leading-none flex items-center justify-center"
                                    >
                                        <svgConnection
                                            v-if="75 <= value.signalStrength"
                                            aria-hidden="true"
                                        />
                                        <svgConnection2
                                            v-else-if="
                                                50 <= value.signalStrength
                                            "
                                            aria-hidden="true"
                                        />
                                        <svgConnection3
                                            v-else-if="
                                                25 <= value.signalStrength
                                            "
                                            aria-hidden="true"
                                        />
                                        <svgConnection4
                                            v-else
                                            aria-hidden="true"
                                        />
                                    </div>

                                    <div
                                        v-if="!value.muted"
                                        class="leading-none flex items-center justify-center"
                                    >
                                        <svgVolumeHigh
                                            v-if="66 <= value.volume"
                                            aria-hidden="true"
                                        />
                                        <svgVolumeMedium
                                            v-else-if="33 <= value.volume"
                                            aria-hidden="true"
                                        />
                                        <svgVolumeLow
                                            v-else-if="15 < value.volume"
                                            aria-hidden="true"
                                        />
                                        <svgVolumeMute
                                            v-else
                                            class="opacity-25"
                                            aria-hidden="true"
                                        />
                                    </div>
                                </div>

                                <div
                                    class="text-right text-white text-xs py-1 px-3 rounded-full tracking-wide absolute bottom-0 right-0 mr-2 mb-2 mg-filter"
                                >
                                    <span class="text-white">{{
                                        formatName(value.info)
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div
            :class="{
                'mg-filter--medium': meetingHasTopic
            }"
            class="mg-control-panel mg-control-panel--view-info w-screen fixed top-0 flex flex-wrap items-center justify-center mg-filter z-10"
        >
            <div class="p-4 py-6 md:px-16 max-w-md text-white text-center">
                <h1
                    :class="{
                        'font-bold': !meetingHasTopic
                    }"
                    class="text-white mb-2 text-lg block leading-tight"
                >
                    {{ meetingTitle }}
                </h1>
                <div
                    class="inline-block rounded"
                    :class="{
                        'bg-red-200': extraMinutesActive,
                        'p-2': extraMinutesActive,
                        'py-1': extraMinutesActive,
                        'text-red-700': extraMinutesActive,
                        'font-bold': extraMinutesActive
                    }"
                >
                    <span ref="meeting-minutes">{{
                        meetingMinutesDisplay
                    }}</span
                    >:<span ref="meeting-seconds">{{
                        meetingSecondsDisplay
                    }}</span>
                </div>
            </div>
        </div>

        <div
            :class="{
                '2xl:z-10 opacity-25 2xl:opacity-100': meetingHasTopic,
                'lg:z-10 opacity-25 lg:opacity-100': !meetingHasTopic
            }"
            class="fixed top-0 left-0 mt-4 ml-4 pointer-events-none"
        >
            <img
                v-if="brandLogo"
                :src="brandLogo"
                class="block meeting-logo"
                alt=""
            />
        </div>

        <div
            :class="{
                active: activeUiElements.includes(
                    'mg-control-panel--secondary-options'
                )
            }"
            class="mg-control-panel mg-control-panel--secondary mg-control-panel--secondary-options w-full max-w-sm mg-filter mg-filter--medium mg-filter--has-hover-heavy fixed top-0 bottom-0 right-0 text-white z-10"
        >
            <div
                class="overflow-y-auto p-4 absolute top-0 bottom-0 w-full mt-10"
            >
                <transition name="page-fade" mode="out-in">
                    <div v-if="'devices' === settingsPage">
                        <!-- TODO: use MgSelect.vue instead -->
                        <device-select
                            v-for="(device, index) in devices"
                            :device="device.name"
                            :label="device.label"
                            :select-options="device.options"
                            class="mb-6"
                            :key="`${device.name}-${index}`"
                        />
                    </div>
                    <nav v-else aria-label="Settings Navigation">
                        <ul
                            class="plain-buttons buttons-have-icon space-y-2 font-bold mb-4"
                        >
                            <li v-if="meetingHasConnection">
                                <button
                                    @click.prevent="goToSettingsPage('devices')"
                                >
                                    <font-awesome-icon
                                        class="mr-4"
                                        aria-hidden="true"
                                        icon="plug"
                                    />
                                    <span class="leading-none">Devices</span>
                                </button>
                            </li>
                            <li>
                                <button @click.prevent="toggleFullScreen">
                                    <svg-shrink
                                        v-if="isFullScreen"
                                        class="mr-4"
                                        skey="icon-shrink"
                                    />
                                    <svg-enlarge
                                        v-else
                                        class="mr-4"
                                        skey="icon-enlarge"
                                    />

                                    <span
                                        v-if="isFullScreen"
                                        class="leading-none"
                                        key="exitfullscreenlabel"
                                    >
                                        Exit fullscreen
                                    </span>
                                    <span
                                        v-else
                                        class="leading-none"
                                        key="fullscreenlabel"
                                    >
                                        View fullscreen
                                    </span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </transition>
            </div>

            <div
                class="h-10 absolute top-0 left-0 w-full border-b border-dividers flex justify-between items-center flex-row-reverse"
            >
                <button
                    @click="
                        toggleUiElement('mg-control-panel--secondary-options')
                    "
                    class="bg-transparent border-0 text-white p-0 mr-4"
                >
                    <font-awesome-icon
                        class="mr-2"
                        aria-hidden="true"
                        icon="times"
                    />
                    <span>Close</span>
                </button>
                <button
                    v-if="settingsPage"
                    @click="goToSettingsPage(null)"
                    class="bg-transparent border-0 text-white ui-navigation-control p-0 ml-4"
                >
                    <font-awesome-icon
                        class="mr-2 pointer-events-none"
                        aria-hidden="true"
                        icon="angle-left"
                    />
                    <span class="pointer-events-none">Back</span>
                </button>
            </div>
        </div>

        <div
            :class="{
                active: activeUiElements.includes('mg-control-panel--tertiary')
            }"
            class="mg-control-panel mg-control-panel--tertiary w-full max-w-sm mg-filter mg-filter--heavy fixed top-0 bottom-0 left-0 text-white z-10"
        >
            <div
                class="h-10 absolute top-0 left-0 w-full border-b border-dividers flex justify-between items-center flex-row-reverse"
            >
                <button
                    @click="toggleUiElement('mg-control-panel--tertiary')"
                    class="bg-transparent border-0 text-white p-0 mr-4"
                >
                    <font-awesome-icon
                        class="mr-2"
                        aria-hidden="true"
                        icon="times"
                    />
                    <span>Close</span>
                </button>

                <div class="w-1/2 mr-2">
                    <button
                        v-if="secondarySettingsPage"
                        @click="goToSecondarySettingsPage(null)"
                        class="bg-transparent border-0 text-white ui-navigation-control p-0 ml-4"
                    >
                        <font-awesome-icon
                            class="mr-2 pointer-events-none"
                            aria-hidden="true"
                            icon="angle-left"
                        />
                        <span class="pointer-events-none">Back</span>
                    </button>
                </div>
            </div>

            <div
                v-if="'chat' === secondarySettingsPage"
                class="top-0 bottom-0 w-full absolute mt-10"
            >
                <div
                    ref="chat-scroll"
                    class="fix-scroll-to-bottom overflow-y-auto p-4 absolute top-0 bottom-0 w-full mb-20"
                    tabindex="0"
                >
                    <message-list
                        dim-meta
                        disable-name-link
                        :message-data="messages"
                        class="text-white"
                    />

                    <div class="fix-scroll-to-bottom__anchor"></div>
                </div>

                <form
                    @submit.prevent="sendChatMessage"
                    class="h-20 absolute bottom-0 left-0 w-full"
                >
                    <div
                        class="absolute top-0 left-0 w-full h-full flex items-center"
                    >
                        <mg-select
                            id="chat-select"
                            defaultSelectOptionLabel="everyone"
                            class="text-xs absolute top-0 left-0 mt-2 ml-4 hidden"
                            label="Chat Select"
                            hide-label
                            slim
                            :options="chatSelectOptions"
                        ></mg-select>

                        <input
                            v-model="message"
                            class="appearance-none bg-transparent border-dividers border-t w-full h-full pt-5 px-2 pl-4 leading-tight outline-none text-white focus:border-accent placeholder-dividers"
                            type="text"
                            placeholder="enter chat messsage"
                            aria-label="Enter chat messsage"
                        />
                    </div>
                    <button class="sr-only">Submit</button>
                </form>
            </div>
            <div
                v-else-if="'promotions' === secondarySettingsPage"
                class="overflow-y-auto p-4 absolute top-0 bottom-0 w-full mt-10"
            >
                <transition-group
                    v-if="promotionQueue.length"
                    name="feed-transition"
                    tag="div"
                    enter-active-class="animated fadeInUp"
                    leave-active-class="animated fadeOut"
                    class="divide-y divide-dividers w-full h-64 -mt-3"
                >
                    <div
                        v-for="(item, index) in promotionQueue"
                        class="py-3 md:flex items-center"
                        :key="`${index}-${item.externalUserId}`"
                    >
                        <div class="md:w-1/2">
                            <div class="font-bold">
                                {{ formatName(item.info) }}
                            </div>
                            <div
                                v-if="item.info && item.info.companyName"
                                class="text-sm opacity-75 truncate"
                            >
                                {{ item.info.companyName }}
                            </div>
                        </div>

                        <div
                            v-if="item.chimeUserId"
                            class="space-x-2 flex justify-end md:w-1/2"
                        >
                            <button
                                v-if="isAttendeePromoted(item.chimeUserId)"
                                key="promote-attendee"
                                @click.prevent="
                                    requestDemoteAttendee(item.chimeUserId)
                                "
                                class="button is-danger is-small"
                            >
                                Demote
                            </button>
                            <button
                                v-else
                                key="demote-attendee"
                                @click.prevent="
                                    requestPromotion(item.chimeUserId)
                                "
                                class="button is-success is-outlined is-small"
                            >
                                <span class="font-bold">
                                    Promote
                                </span>
                            </button>
                        </div>
                    </div>
                </transition-group>
                <div v-else key="no-promos-requested">
                    No promotion requests.
                </div>
            </div>
            <nav
                v-else
                class="overflow-y-auto p-4 absolute top-0 bottom-0 w-full mt-10"
            >
                <ul
                    class="plain-buttons buttons-have-icon space-y-2 font-bold mb-4"
                >
                    <li v-if="meetingHasConnection">
                        <button
                            class="ui-navigation-control"
                            @click.prevent="goToSecondarySettingsPage('chat')"
                        >
                            <font-awesome-icon
                                class="mr-4 pointer-events-none"
                                aria-hidden="true"
                                icon="comment"
                            />
                            <span class="leading-none pointer-events-none"
                                >Chat</span
                            >
                        </button>
                    </li>
                    <li
                        v-if="
                            meetingHasConnection &&
                                isPresentation &&
                                isPresenterOrSuperUser
                        "
                    >
                        <button
                            class="ui-navigation-control"
                            @click.prevent="
                                goToSecondarySettingsPage('promotions')
                            "
                        >
                            <font-awesome-icon
                                class="mr-4 pointer-events-none"
                                aria-hidden="true"
                                icon="users-cog"
                            />
                            <span class="leading-none pointer-events-none"
                                >Promotion requests</span
                            >
                        </button>
                    </li>
                </ul>
            </nav>
        </div>

        <div
            ref="mg-control-panel--primary"
            class="mg-control-panel mg-control-panel--primary w-screen fixed bottom-0 flex flex-wrap items-center justify-between mg-filter mg-filter--has-hover-medium z-50 overflow-visible"
        >
            <!-- control group 1 -->
            <div
                class="p-4 flex flex-wrap items-center flex-1 md:justify-start"
            >
                <b-tooltip
                    v-if="
                        meetingHasConnection &&
                            isPresentation &&
                            isPresenterOrSuperUser
                    "
                    :label="
                        isPromotionsPageActive
                            ? 'Hide Promotion requests'
                            : 'Show Promotion requests'
                    "
                    type="is-dark"
                    :delay="500"
                    position="is-right"
                >
                    <button
                        @click="handleTertiaryPanel('promotions')"
                        class="bg-transparent border-0 text-white text-xl xl:text-2xl rounded-full mx-2 relative"
                    >
                        <font-awesome-icon
                            class="m-0"
                            aria-hidden="true"
                            icon="users-cog"
                        />
                        <div class="sr-only">Promotion requests</div>

                        <span
                            v-if="unreadPromotionsCount"
                            class="inline-block bg-accent text-white text-xs px-1 rounded-full uppercase font-semibold tracking-wide absolute bottom-0 right-0 translate-x-2 translate-y-1 transform"
                            >{{ unreadPromotionsCount }}</span
                        >
                    </button>
                </b-tooltip>

                <b-tooltip
                    v-if="meetingHasConnection"
                    :label="isChatActive ? 'Hide Chat' : 'Show Chat'"
                    type="is-dark"
                    :delay="500"
                    position="is-top"
                >
                    <button
                        @click="handleTertiaryPanel('chat')"
                        class="bg-transparent border-0 text-white text-xl xl:text-2xl rounded-full mx-2 relative"
                    >
                        <font-awesome-icon
                            class="mr-0"
                            aria-hidden="true"
                            icon="comment"
                        />
                        <div class="sr-only">Chat</div>

                        <span
                            v-if="unreadMessagesCount"
                            class="inline-block bg-accent text-white text-xs px-1 rounded-full uppercase font-semibold tracking-wide absolute bottom-0 right-0 translate-x-2 translate-y-1 transform"
                            >{{ unreadMessagesCount }}</span
                        >
                    </button>
                </b-tooltip>

                <b-tooltip
                    v-if="meetingHasConnection && allowScreenShare"
                    :label="
                        myContentIsSharing
                            ? 'Stop sharing'
                            : 'Share your screen'
                    "
                    :type="myContentIsSharing ? 'is-danger' : 'is-dark'"
                    :always="myContentIsSharing"
                    :delay="500"
                    position="is-top"
                >
                    <button
                        v-if="hasScreenCapture"
                        @click="toggleContentShare()"
                        class="bg-transparent border-0 text-white text-xl xl:text-2xl rounded-full mx-2"
                    >
                        <font-awesome-icon aria-hidden="true" icon="desktop" />
                        <div class="sr-only">Share screen</div>
                    </button>
                </b-tooltip>

                <b-tooltip
                    v-if="
                        meetingHasConnection &&
                            isPresentation &&
                            !isPresenterOrSuperUser
                    "
                    :label="handIsRaised ? 'Lower hand' : 'Raise hand'"
                    :type="handIsRaised ? 'is-danger' : 'is-dark'"
                    :always="handIsRaised"
                    :delay="500"
                    position="is-top"
                >
                    <button
                        @click.prevent="toggleRaiseHand"
                        class="bg-transparent border-0 text-white text-xl xl:text-2xl rounded-full mx-2 leading-none"
                    >
                        <svg-hand aria-hidden="true" />
                        <div class="sr-only">Ask to speak</div>
                    </button>
                </b-tooltip>
            </div>

            <!-- control group 2 -->
            <div class="p-4 flex flex-1 justify-center">
                <b-tooltip
                    v-if="
                        meetingHasConnection &&
                            currentAudioInput &&
                            (canAddAudio || !muted)
                    "
                    :label="muted ? 'Un-mute' : 'Mute'"
                    type="is-dark"
                    :delay="500"
                    position="is-top"
                    key="audioEnabled"
                >
                    <button
                        @click.prevent="setMuted"
                        class="ui-navigation-control bg-white h-12 w-12 text-xl rounded-full mx-4 border-0 flex items-center justify-center p-0"
                    >
                        <svg-mic-off
                            v-if="muted"
                            aria-hidden="true"
                            class="pointer-events-none"
                        />
                        <svg-mic
                            v-else
                            aria-hidden="true"
                            class="pointer-events-none"
                        />

                        <span
                            v-if="muted"
                            class="sr-only pointer-events-none"
                            key="muted"
                            >Unmute</span
                        >
                        <span
                            v-else
                            class="sr-only pointer-events-none"
                            key="unmuted"
                            >Mute</span
                        >
                    </button>
                </b-tooltip>
                <b-tooltip
                    v-else-if="meetingHasConnection"
                    :label="disabledMicrophoneLabel"
                    type="is-dark"
                    :delay="500"
                    position="is-top"
                    key="audioNotEnabled"
                >
                    <button
                        disabled
                        class="bg-white h-12 w-12 text-xl rounded-full mx-4 border-0 flex items-center justify-center p-0"
                        type="button"
                    >
                        <svg-mic-off aria-hidden="true" />
                        <span class="sr-only">
                            {{ disabledMicrophoneLabel }}
                        </span>
                    </button>
                </b-tooltip>

                <b-tooltip
                    label="Leave meeting"
                    type="is-dark"
                    :delay="500"
                    position="is-top"
                >
                    <button
                        @click.prevent="leaveMeeting"
                        class="bg-red-700 border-0 text-white h-12 w-12 text-xl rounded-full mx-4 flex items-center justify-center p-0"
                    >
                        <font-awesome-icon aria-hidden="true" icon="times" />
                        <div class="sr-only">Leave meeting</div>
                    </button>
                </b-tooltip>

                <b-tooltip
                    v-if="
                        meetingHasConnection &&
                            currentVideoDevice &&
                            (canAddVideo || cameraOn)
                    "
                    :label="cameraOn ? 'Disable camera' : 'Enable camera'"
                    type="is-dark"
                    :delay="500"
                    position="is-top"
                >
                    <button
                        class="ui-navigation-control bg-white h-12 w-12 text-xl rounded-full mx-4 border-0 flex items-center justify-center p-0"
                        @click="toggleVideo()"
                    >
                        <svg-camera
                            v-if="cameraOn"
                            aria-hidden="true"
                            class="pointer-events-none"
                        />
                        <svg-camera-slash
                            v-else
                            aria-hidden="true"
                            class="pointer-events-none"
                        />

                        <span
                            v-if="cameraOn"
                            class="sr-only pointer-events-none"
                            key="cameraOn"
                            >Disable camera</span
                        >
                        <span
                            v-else
                            class="sr-only pointer-events-none"
                            key="cameraOff"
                            >Enable camera</span
                        >
                    </button>
                </b-tooltip>

                <b-tooltip
                    v-else-if="meetingHasConnection"
                    :label="disabledCameraLabel"
                    type="is-dark"
                    :delay="500"
                    position="is-top"
                >
                    <button
                        disabled
                        class="bg-white h-12 w-12 text-xl rounded-full mx-4 border-0 flex items-center justify-center p-0"
                        type="button"
                    >
                        <svg-camera-slash aria-hidden="true" />
                        <span class="sr-only" key="cameraOff">
                            {{ disabledCameraLabel }}
                        </span>
                    </button>
                </b-tooltip>
            </div>

            <!-- control group 3 -->
            <div class="p-4 flex flex-1 md:justify-end">
                <div v-if="isPresentation" class="relative">
                    <transition
                        tag="div"
                        enter-active-class="animated fadeInUp"
                        leave-active-class="animated fadeOut"
                    >
                        <div
                            v-if="activeUiElements.includes('attendee-list')"
                            class="absolute top-0 -mt-4 left-half bg-accent w-0"
                        >
                            <div
                                class="transform -translate-y-full sm:-translate-x-1/2 w-56"
                            >
                                <div
                                    class="overflow-hidden bg-white shadow rounded w-full transform"
                                >
                                    <div class="px-4 py-2 overflow-y-auto">
                                        <p
                                            class="text-sm font-bold opacity-50 m-0"
                                        >
                                            Attendees
                                        </p>
                                        <div
                                            class="divide-y divide-dividers w-full h-64"
                                        >
                                            <div
                                                v-for="(value,
                                                key,
                                                index) in attendeeListing"
                                                class="py-2"
                                                :key="index"
                                            >
                                                {{ value.info.displayName }}
                                                <div
                                                    v-if="
                                                        value.info.companyName
                                                    "
                                                    class="text-sm opacity-50 truncate"
                                                >
                                                    {{ value.info.companyName }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    @click="toggleUiElement('attendee-list')"
                                    class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 border-0 bg-white h-8 w-8 rounded-full shadow p-0 flex items-center justify-center"
                                >
                                    <font-awesome-icon
                                        aria-hidden="true"
                                        icon="times"
                                    />
                                    <span class="sr-only">Close attendees</span>
                                </button>
                            </div>
                        </div>
                    </transition>
                    <b-tooltip
                        v-if="meetingHasConnection"
                        label="Toggle attendee list"
                        type="is-dark"
                        :delay="500"
                        position="is-left"
                    >
                        <button
                            @click="toggleUiElement('attendee-list')"
                            class="bg-transparent border-0 text-white text-xl xl:text-2xl rounded-full mx-2"
                        >
                            <font-awesome-icon
                                aria-hidden="true"
                                icon="users"
                            />
                            <div class="sr-only">Toggle attendee list</div>
                        </button>
                    </b-tooltip>
                </div>

                <b-tooltip
                    v-if="meetingHasConnection"
                    label="Toggle grid view"
                    type="is-dark"
                    :delay="500"
                    position="is-top"
                >
                    <button
                        v-if="!$data.contentTile"
                        @click.prevent="toggleGridView"
                        class="bg-transparent border-0 text-white text-xl xl:text-2xl rounded-full mx-2"
                    >
                        <font-awesome-icon
                            class="mr-0"
                            aria-hidden="true"
                            icon="th-large"
                        />
                        <div class="sr-only">Toggle grid view</div>
                    </button>
                </b-tooltip>
                <b-tooltip
                    label="Settings"
                    type="is-dark"
                    :delay="500"
                    position="is-left"
                >
                    <button
                        @click="
                            toggleUiElement(
                                'mg-control-panel--secondary-options'
                            )
                        "
                        class="bg-transparent border-0 text-white text-xl xl:text-2xl rounded-full ml-2 mr-4"
                    >
                        <font-awesome-icon aria-hidden="true" icon="cog" />
                        <div class="sr-only">Settings</div>
                    </button>
                </b-tooltip>
            </div>
        </div>

        <transition-group
            tag="div"
            class="tracking-wide fixed bottom-0 left-0 mb-4 ml-4 space-x-2 flex"
            enter-active-class="animated fadeInUp"
            leave-active-class="animated fadeOut"
        >
            <div
                v-if="
                    panelsAreClosed &&
                        unreadPromotionsCount &&
                        isPresentation &&
                        isPresenterOrSuperUser
                "
                key="unread-attendee-options"
                class="text-white text-xs py-1 px-3 rounded-full mg-filter z-10"
            >
                <font-awesome-icon
                    class="mr-2 inline-block"
                    aria-hidden="true"
                    icon="users-cog"
                />

                <span aria-hidden="true">{{ unreadPromotionsCount }}</span>
                <span role="alert" class="sr-only"
                    >There are some new attendee options</span
                >
            </div>

            <div
                key="unread-comments"
                v-if="panelsAreClosed && unreadMessagesCount"
                class="text-white text-xs py-1 px-3 rounded-full mg-filter z-10"
            >
                <font-awesome-icon
                    class="mr-2 inline-block"
                    aria-hidden="true"
                    icon="comment"
                />
                <span aria-hidden="true">{{ unreadMessagesCount }}</span>
                <span role="alert" class="sr-only"
                    >There are some unread chat messages</span
                >
            </div>
        </transition-group>

        <!-- this needs to be last child element -->
        <div
            v-if="isLoading"
            key="loading-data"
            class="fixed z-50 top-0 left-0 w-screen h-screen bg-white flex items-center"
        >
            <b-modal
                v-addbuefymodal
                :active.sync="showInputsModal"
                trap-focus
                :width="700"
                :can-cancel="false"
            >
                <form
                    class="bg-white px-8 py-6 content"
                    @submit.prevent="handleInputModalSubmit"
                >
                    <device-select
                        v-for="(device, index) in devices"
                        :device="device.name"
                        :label="device.label"
                        :select-options="device.options"
                        class="mb-6"
                        :key="`${device.name}-${index}`"
                    />
                    <button class="button pill-button is-primary">
                        Enter meeting
                    </button>
                </form>
            </b-modal>

            <spinners />
        </div>
    </div>
</template>

<script>
import linkifyHtml from "linkifyjs/html";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";
import {
    AsyncScheduler,
    ConsoleLogger,
    DataMessage,
    DefaultActiveSpeakerPolicy,
    DefaultDeviceController,
    DefaultMeetingSession,
    LogLevel,
    MeetingSessionConfiguration
} from "amazon-chime-sdk-js";
import { mapState, mapGetters, mapActions } from "vuex";
import { differenceInSeconds } from "date-fns";
import { getModule } from "vuex-module-decorators";

import attendeeVuexModule from "@/store/vuex-modules/attendees";
import appointmentsVuexModule from "@/store/vuex-modules/appointments";

import messageList from "@/components/MessageList";
import mgSelect from "@/components/shared/MgSelect";
import deviceSelect from "@/components/shared/device-select";
import svgHand from "@/components/svg/svg-hand";
import svgCamera from "@/components/svg/svg-camera";
import svgCameraSlash from "@/components/svg/svg-camera-slash";
import svgMic from "@/components/svg/svg-mic";
import svgMicOff from "@/components/svg/svg-mic-off";
import svgShrink from "@/components/svg/svg-shrink";
import svgEnlarge from "@/components/svg/svg-enlarge";
import svgConnection from "@/components/svg/svg-connection";
import svgConnection2 from "@/components/svg/svg-connection-2";
import svgConnection3 from "@/components/svg/svg-connection-3";
import svgConnection4 from "@/components/svg/svg-connection-4";
import svgVolumeHigh from "@/components/svg/svg-volume-high";
import svgVolumeMedium from "@/components/svg/svg-volume-medium";
import svgVolumeLow from "@/components/svg/svg-volume-low";
import svgVolumeMute from "@/components/svg/svg-volume-mute";
import eventHub from "@/event-hub";
import spinners from "@/components/utilities/Spinners.vue";

const attendeeStore = getModule(attendeeVuexModule);
const appointmentsStore = getModule(appointmentsVuexModule);

import logVuexModule from "@/store/vuex-modules/log";
const logStore = getModule(logVuexModule);

const siteSystemPermissionError =
    "Check your system settings. Your system is not allowing your browser to use your camera.";
const siteVideoPermissionError =
    "Check site settings. You've denied this site permission to use your camera. Please allow this site to use your camera.";
const ONE_MINUTE = 60;
const DEFAULT_MEETING_DURATION = 240 * ONE_MINUTE;

// Todo: rename this file MeetingView.vue
// https://vuejs.org/v2/style-guide/#Multi-word-component-names-essential
export default {
    name: "MeetingView",
    components: {
        svgHand,
        svgCamera,
        svgCameraSlash,
        svgMic,
        svgMicOff,
        svgShrink,
        svgEnlarge,
        mgSelect,
        deviceSelect,
        messageList,
        spinners,
        svgConnection,
        svgConnection2,
        svgConnection3,
        svgConnection4,
        svgVolumeHigh,
        svgVolumeMedium,
        svgVolumeLow,
        svgVolumeMute
    },
    directives: {
        addvideoel: {
            inserted: function(el, binding, vnode) {
                const id = el.getAttribute("data-attendee-id");
                let video = null;
                const rosterItem = vnode.context.roster[id];

                if (rosterItem && Object.keys(rosterItem).length) {
                    video = rosterItem.video;
                }

                if (video instanceof HTMLVideoElement) {
                    video.className = vnode.context.videoElementClasses;
                    el.innerHTML = "";
                    el.appendChild(video);

                    const playPromise = video.play();
                    if (playPromise instanceof Promise) {
                        playPromise
                            .then(() => {
                                // n/a
                            })
                            .catch(() => {
                                // n/a
                            });
                    }
                }
            }
        },
        addspeakerel: {
            inserted: function(el, binding, vnode) {
                vnode.context.handleSpeakerElement(el);
            }
        },
        addbuefymodal: {
            bind: function(el) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    },
    data() {
        return {
            allowedRoutes: [
                "Sessions",
                "scheduleComponent",
                "ExhibitorDetails",
                "PosterDetails",
                "NetworkingLanding",
                "MeetingView",
                "Home"
            ],
            errorMessage: "",
            showInputsModal: false,
            meetingTitle: "",
            brandLogo: "",
            initialBrandColor: "",
            meetingHasTopic: false,
            meetingHasConnection: false,
            secondsCountDown: 0,
            creationTime: null,
            meetingApiData: null,
            isLoading: true,
            hasScreenCapture: false,
            boundAudioElement: false,
            chatSelectOptions: [],
            chatSelection: "",
            gridViewIsActive: true,
            chime: {
                meetingSession: null
            },
            uiWrapper: null,
            uiObserver: null,
            meetingCount: null,
            maxVideoFeeds: 16,
            meetingSecondsInitial: 0,
            meetingMinutesDisplay: "00",
            meetingSecondsDisplay: "00",
            extraMinutesActive: false,
            timeRemaining: 0,
            meetingIsOver: false,
            unknownName: "Loading...",
            settingsPage: "",
            secondarySettingsPage: "",
            message: "",
            currentSpeaker: undefined,
            activeUiElements: [],
            videoFeeds: {},
            attendeeInfo: {},
            isPromoted: false,
            videoAttendees: [], // Attendees who are active on video
            audioAttendees: [], // Attendees who are not currently active on video
            handIsRaised: false,
            raisedHands: [],
            raisedHandsAtPanelClosed: [],
            promotedFolks: [],
            silentAttendees: [], // Observers in presentation mode
            idleTimer: null,
            idleState: false,
            idleWait: 1500,
            speakerInitialized: true,
            meetingId: "",
            grabCursor: true,
            audioInputs: [],
            audioOutputs: [],
            videoInputs: [],
            canStartLocalVideo: false,
            remoteVideoAvailable: false,
            roster: {},
            rosterVideos: {},
            messageTopics: [
                "chat",
                "raisehand",
                "lowerhand",
                "promote",
                "demote"
            ],
            DATA_MESSAGE_LIFETIME_MS: 600000,
            lastReceivedMessageTimestamp: 0,
            cameraOn: false,
            cameraOffSelect: true,
            muted: false,
            myContentIsSharing: false,
            canAddVideo: false,
            canAddAudio: false,
            currentAudioInput: "",
            currentAudioOutput: "",
            currentVideoDevice: "",
            myTile: null,
            contentTile: null,
            currentSpeakerEl: null,
            nodeToShow: null,
            messages: [],
            messagesAtPanelClosed: 0,
            videoElementClasses:
                "h-full w-full top-0 left-0 absolute object-cover z-0"
        };
    },
    computed: {
        ...mapState(["user", "isFullScreen", "route"]),
        ...mapGetters([
            "isProduction",
            "logoMeetingView",
            "conferenceName",
            "getConfigNavItem",
            "isSuperUser"
        ]),
        extraMinutes() {
            const returnValue = this.$store.state.settingsVuexModule
                .speakeasyExtraMinutes;
            return returnValue;
        },
        extraSeconds() {
            return this.extraMinutes * ONE_MINUTE;
        },
        extraSecondsOvertime() {
            // Subtract an additional 2 seconds
            // the additional 2 seconds is for the time in which the countdown display is reset
            return this.extraSeconds * -1 - 2;
        },

        route() {
            return this.$store.state.route || {};
        },

        exitTo() {
            const scheduleConfigNavItem = this.getConfigNavItem("Schedule");
            const sessionsConfigNavItem = this.getConfigNavItem("Sessions");
            const scheduleActive = Boolean(
                scheduleConfigNavItem && scheduleConfigNavItem.active
            );
            const programActive = Boolean(
                sessionsConfigNavItem && sessionsConfigNavItem.active
            );
            let returnValue = "/";
            const routerFrom = this.route.from;
            let outboundURL = this.getMeetingData("outboundURL");
            const comingFromMeetingView =
                Boolean("MeetingView" === routerFrom?.name) ||
                Boolean("MeetingDeveloper" === routerFrom?.name);

            if (outboundURL instanceof String) {
                outboundURL = outboundURL.trim();
            }

            if (outboundURL) {
                returnValue = outboundURL;
            } else if (
                !comingFromMeetingView &&
                scheduleActive &&
                programActive &&
                this.allowedRoutes.includes(routerFrom?.name)
            ) {
                returnValue = routerFrom.fullPath;
            } else if (scheduleActive) {
                returnValue = scheduleConfigNavItem.path;
            } else if (programActive) {
                returnValue = sessionsConfigNavItem.path;
            }

            return returnValue;
        },

        isDeveloperRoute() {
            return Boolean("MeetingDeveloper" === this.route.name);
        },

        allowLoadMeeting() {
            // There is a reactivity issue with `this.roster` when visitors direct browse to a speakeasy
            // for now, kick visitors out if they try direct browse.
            // The `MeetingDeveloper` backdoor is intended only for support personel / attendees should not know about this option.

            const isNormalRouteAndAllowed = Boolean(
                "MeetingView" === this.route.name &&
                    this.allowedRoutes.includes(this.route?.from?.name)
            );

            return this.isDeveloperRoute || isNormalRouteAndAllowed;
        },

        currentSpeakerData() {
            let returnValue = null;

            if (this.currentSpeaker && this.roster[this.currentSpeaker]) {
                returnValue = this.roster[this.currentSpeaker];
            }

            return returnValue;
        },

        promotionQueue() {
            const source = this.raisedHands;
            const returnValue = [];

            source.map((item) => {
                const attendeeId = item.split("@")[0];
                const isSelf = Boolean(this.getSelfAttendeeId() === attendeeId);
                const updateItem = this.roster[attendeeId];

                if (!isSelf && updateItem) {
                    updateItem.chimeUserId = attendeeId;
                    returnValue.push(updateItem);
                }
            });

            return returnValue;
        },
        disabledMicrophoneLabel() {
            let returnValue = "Audio at capacity";

            if (!this.currentAudioInput) {
                returnValue = "No audio input selected";
            } else if (this.isPresentation) {
                returnValue = "Microphone not allowed";
            }

            return returnValue;
        },
        disabledCameraLabel() {
            let returnValue = "Video at capacity";

            if (!this.currentVideoDevice) {
                returnValue = "No video input selected";
            } else if (this.isPresentation) {
                returnValue = "Camera not allowed";
            }

            return returnValue;
        },
        vaAttendees() {
            // Attendees who are currently voice or audio (non-silent)
            return [...this.videoAttendees, ...this.audioAttendees];
        },
        uniqueAttendees() {
            const returnValue = [];
            const attendeeIds = [];

            for (const [key, value] of Object.entries(this.roster)) {
                const attendeeId = value?.info?.attendeeId;
                if (attendeeId && !attendeeIds.includes(attendeeId)) {
                    returnValue.push(key);
                    attendeeIds.push(attendeeId);
                }
            }

            return returnValue;
        },
        attendeeListing() {
            const source = this.uniqueAttendees;
            const returnValue = {};

            source.map((item) => {
                if (this.roster[item]) {
                    returnValue[item] = this.roster[item];
                }
            });

            return returnValue;
        },
        attendeesForDisplay() {
            const source = this.vaAttendees;
            const returnValue = {};

            source.map((item) => {
                const rosterItem = this.roster[item];
                if (!rosterItem) return;
                returnValue[item] = rosterItem;
            });

            return returnValue;
        },
        queriedAttendee: {
            get() {
                return attendeeStore.attendee;
            },
            set() {
                // n/a
            }
        },
        meetingDuration() {
            let returnValue = 0;
            let startTime = null;
            let endTime = null;

            if (
                this.meetingData &&
                this.meetingData.startTimeISO &&
                this.meetingData.endTimeISO
            ) {
                startTime = new Date(this.meetingData.startTimeISO);
                endTime = new Date(this.meetingData.endTimeISO);

                returnValue = differenceInSeconds(endTime, startTime);
            } else {
                returnValue = DEFAULT_MEETING_DURATION;
            }

            return returnValue;
        },
        isUiActive() {
            return Boolean(
                !this.idleState ||
                    this.activeUiElements.length ||
                    this.extraMinutesActive
            );
        },
        isTertiaryPanelActive() {
            return Boolean(
                this.activeUiElements.includes("mg-control-panel--tertiary")
            );
        },
        panelsAreClosed() {
            return Boolean(
                0 === this.activeUiElements.length && this.idleState
            );
        },
        isChatActive() {
            const isChatOpen = Boolean(
                "chat" === this.secondarySettingsPage &&
                    this.isTertiaryPanelActive
            );

            return isChatOpen;
        },
        isPromotionsPageActive() {
            const returnValue = Boolean(
                "promotions" === this.secondarySettingsPage &&
                    this.isTertiaryPanelActive
            );

            return returnValue;
        },
        unreadPromotionsCount() {
            const newItems = this.raisedHands.filter((item) => {
                if (!this.isPromotionsPageActive) {
                    return !this.raisedHandsAtPanelClosed.includes(item);
                } else {
                    return false;
                }
            });

            if (!this.isPromotionsPageActive) {
                return newItems.length;
            } else {
                return 0;
            }
        },
        unreadMessagesCount() {
            const unread = this.messages.length - this.messagesAtPanelClosed;

            if (null === this.messagesAtPanelClosed) {
                return 0;
            } else if (unread > 0) {
                return unread;
            } else {
                return 0;
            }
        },
        devices() {
            const deviceArray = [
                {
                    name: "audio_input",
                    label: "Microphones",
                    options: this.audioInputs
                },
                {
                    name: "audio_output",
                    label: "Speakers",
                    options: this.audioOutputs
                },
                {
                    name: "video_input",
                    label: "Camera",
                    options: this.videoInputs
                }
            ];

            return deviceArray;
        },
        hasMultiMediaOptions() {
            return (
                Boolean(this.currentAudioInput) &&
                Boolean(this.currentAudioOutput) &&
                Boolean(this.currentVideoDevice)
            );
        },
        meetingData() {
            let returnValue = null;
            if (this.meetingApiData && this.meetingApiData.meetingData) {
                returnValue = this.meetingApiData.meetingData;
            }
            return returnValue;
        },
        isPresentation() {
            return Boolean("presentation" === this.getMeetingData("type"));
        },
        meetingAttendeeData() {
            const data = this.meetingApiData.attendeeData;
            return data;
        },
        isMeetingPresenter() {
            return Boolean(
                this.meetingAttendeeData && this.meetingAttendeeData.isPresenter
            );
        },
        isPresenterOrSuperUser() {
            const superuser = this.isSuperUser;
            const presenter = this.isMeetingPresenter;
            return superuser || presenter;
        },
        allowScreenShare() {
            const disableMeetingTypes = ["presentation"];
            const isDisableType = disableMeetingTypes.includes(
                this.getMeetingData("type")
            );
            let returnValue = true;

            if (isDisableType) {
                if (this.isPresentation) {
                    returnValue = Boolean(
                        this.isPresentation && this.isPresenterOrSuperUser
                    );
                }
            }

            return returnValue;
        }
    },
    watch: {
        isPromoted() {
            this.handleControlPermissions();
        },
        roster: {
            handler(val, oldVal) {
                const options = [];

                Object.keys(this.roster).map((key) => {
                    const attendee = this.roster[key];

                    if (attendee && attendee.info) {
                        const option = {
                            value: attendee.info.attendeeId,
                            label: attendee.info.displayName
                        };
                        options.push(option);
                    }
                });

                this.chatSelectOptions = options;
            },
            deep: true
        },
        isChatActive() {
            if (this.isChatActive) {
                this.messagesAtPanelClosed = null;
            } else {
                this.messagesAtPanelClosed = this.messages.length;
            }
        },
        isPromotionsPageActive() {
            if (this.isPromotionsPageActive) {
                this.raisedHandsAtPanelClosed = [];
            } else {
                const raisedHandsSnapshot = JSON.parse(
                    JSON.stringify(this.raisedHands)
                );
                this.raisedHandsAtPanelClosed = raisedHandsSnapshot;
            }
        },
        meetingIsOver() {
            // kick everyone out
            if (this.meetingIsOver) {
                this.leaveMeeting();
            }
        },
        currentSpeaker() {
            this.$nextTick(() => {
                this.handleSpeakerElement();
            });
        }
    },
    created() {
        if (!this.allowLoadMeeting) {
            const redirectTo = this.exitTo;
            // leave and do not run additional code
            return this.$router.push(redirectTo);
        }

        this.creationTime = this.MgRightNow();
        this.loadSavedPreferences();

        // TODO
        // set up use a vuex module to contain all feature detects,
        // best to use vuex module in case we need these elsewhere.
        this.handleFeatureDetection();

        window.addEventListener("beforeunload", this.cleanupChime);

        window.addEventListener("mousemove", this.handleIdleCount);
        window.addEventListener("click", this.handleIdleCount);
        window.addEventListener("keydown", this.handleIdleCount);
        window.addEventListener("touchstart", this.handleIdleCount);

        eventHub.$on("key-pressed-escape", this.clearActiveUi);
        eventHub.$on("select-selection", this.handleChatSelect);
        eventHub.$on("BuefyModalBound", this.handleBuefyModalFixes);

        eventHub.$on("speakeasy-device-selection", this.handleDeviceSelect);
    },

    async mounted() {
        if (!this.allowLoadMeeting) {
            // do not run additional code
            return;
        }

        // Init depends on DOM elements
        await this.init().catch(() => {
            console.error("Init failed.");
            this.isLoading = false;
        });

        // UI setup
        this.uiWrapper = this.$refs["mg-control-panel-wrapper"];

        if (this.uiWrapper) {
            this.uiWrapper.addEventListener("click", this.handleUiCancelLayer);
            this.uiWrapper.addEventListener(
                "touchstart",
                this.handleUiCancelLayer
            );

            if ("ResizeObserver" in window) {
                this.uiObserver = new ResizeObserver(this.handleUiDefinitions);
                this.uiObserver.observe(this.uiWrapper);
            } else {
                this.handleUiDefinitions();
            }
        }
    },

    // It's good to clean up event listeners before
    // a component is destroyed.
    async beforeDestroy() {
        /**
            assign any async code to variables first,
            then `await` those variables at the end of this method.
            doing this will speed things up.
         */
        const cleanup = this.cleanupChime();

        clearInterval(this.meetingCount);
        clearInterval(this.logInterval);

        if (this.uiWrapper) {
            this.uiWrapper.removeEventListener(
                "click",
                this.handleUiCancelLayer
            );
            this.uiWrapper.removeEventListener(
                "touchstart",
                this.handleUiCancelLayer
            );

            if ("ResizeObserver" in window) {
                this.uiObserver.unobserve(this.uiWrapper);
            }
        }

        // TODO / note: This appears to work in chromium browsers (although inconsistently) but not FF.
        window.removeEventListener("beforeunload", this.cleanupChime);

        window.removeEventListener("mousemove", this.handleIdleCount);
        window.removeEventListener("click", this.handleIdleCount);
        window.removeEventListener("keydown", this.handleIdleCount);
        window.removeEventListener("touchstart", this.handleIdleCount);

        eventHub.$off("key-pressed-escape", this.clearActiveUi);
        eventHub.$off("select-selection", this.handleChatSelect);
        eventHub.$off("BuefyModalBound", this.handleBuefyModalFixes);

        eventHub.$off("speakeasy-device-selection", this.handleDeviceSelect);

        // await variables here
        await cleanup;
    },

    methods: {
        ...mapActions(["toggleFullScreen"]),

        loadSavedPreferences() {
            this.currentAudioInput = localStorage.getItem(
                "preferredAudioInput"
            );
            this.currentAudioOutput = localStorage.getItem(
                "preferredAudioOutput"
            );
            this.currentVideoDevice = localStorage.getItem(
                "preferredVideoInput"
            );
        },
        handleInputModalSubmit() {
            this.handleInitStep2();
        },
        handleMeetingPreferences() {
            this.loadLogo();
            this.setColors();
            this.setMeetingTitle();
        },
        loadLogo() {
            if (this.getMeetingData("meetingLogo")) {
                this.brandLogo = this.getMeetingData("meetingLogo");
            } else {
                this.brandLogo = this.logoMeetingView;
            }
        },
        setColors() {
            const documentElement = document.documentElement;
            let brandColor = "";

            if (this.getMeetingData("meetingColor")) {
                brandColor = this.meetingData.meetingColor;
            } else if (this.initialBrandColor) {
                brandColor = this.initialBrandColor;
            }

            if (brandColor) {
                documentElement.style.setProperty("--brand", brandColor);
            }
        },
        setMeetingTitle() {
            const topic = this.getMeetingData("displayTopic");

            if (topic) {
                this.meetingTitle = topic;
                this.meetingHasTopic = true;
            } else if (this.conferenceName) {
                this.meetingTitle = this.conferenceName;
            }
        },
        getMeetingData(property = "") {
            let returnValue = "";
            if (this.meetingData && this.meetingData[property]) {
                returnValue = this.meetingData[property];
            }
            return returnValue;
        },
        setInitialBrandColor() {
            const documentElement = document.documentElement;
            const primaryColor = documentElement.style.getPropertyValue(
                "--primary"
            );
            const brandColor = documentElement.style.getPropertyValue(
                "--brand"
            );
            if (brandColor) {
                this.initialBrandColor = brandColor;
            } else if (primaryColor) {
                this.initialBrandColor = primaryColor;
            } else {
                this.initialBrandColor = "#222";
            }
        },
        logKickedOutEvent() {
            this.$rollbar.info(
                `Kicked out of Meeting: ${this.$route.params.id}, user: ${
                    this.user.id
                }, timeRemaining: ${this.timeRemaining}, secondsCountDown: ${
                    this.secondsCountDown
                }, creationTime: ${
                    this.creationTime
                }, serverTime: ${this.MgRightNow()}`
            );
        },
        getSelfAttendeeId() {
            // TODO: `selfAttendeeId` should be a computed property.
            // for now not using computed property becuase of reactivity issue.
            const returnValue = "";
            let myId = "";
            if (this.chime.meetingSession) {
                myId = this.chime.meetingSession?.configuration?.credentials
                    ?.attendeeId;
            }
            return myId || returnValue;
        },
        attendeeIsPresenter(attendeeId) {
            /**
             * Note:
             * Ideally, we should not include superusers in `this.meetingData.presenters`
             * This allows us the flexibility to more easily treat superuser differently than actual presenters.
             * In presentation mode:
             * Superusers will have presenter UI.
             * Superusers have video / audio capability by default - Superusers do not require promotion.
             * Room attendees will only see a tile for superusers if superusers enable their camera.
             * Superusers will be listed in attendee list like everyone else.
             */

            if (!attendeeId) {
                return false;
            }

            return Boolean(
                this.meetingData &&
                    this.meetingData.presenters &&
                    this.meetingData.presenters.includes(attendeeId)
            );
        },
        setMeetingTimeRemaining() {
            let returnValue = 0;
            let startTime = 0;
            let timeSinceStart = null;
            const currentTime = this.MgRightNow();

            if (this.meetingData && this.meetingData.startTimeISO) {
                startTime = new Date(this.meetingData.startTimeISO);
            } else {
                startTime = this.creationTime;
            }

            if (currentTime && startTime && 0 < this.meetingDuration) {
                timeSinceStart = differenceInSeconds(currentTime, startTime);

                if (timeSinceStart > -1) {
                    returnValue = this.meetingDuration - timeSinceStart;
                } else {
                    const earlySeconds = timeSinceStart * -1;
                    returnValue = earlySeconds + this.meetingDuration;
                }
            }

            this.timeRemaining = returnValue;
        },
        handleFeatureDetection() {
            this.hasScreenCapture = Boolean(
                (navigator.mediaDevices &&
                    navigator.mediaDevices.getDisplayMedia) ||
                    navigator.getDisplayMedia
            );
        },
        removeElement(array, elem) {
            while (array.indexOf(elem) > -1) {
                const index = array.indexOf(elem);
                if (index > -1) {
                    array.splice(index, 1);
                }
            }
        },
        addVideoMember(id) {
            if (this.videoAttendees.indexOf(id) < 0) {
                this.videoAttendees.push(id);
            }
            this.removeElement(this.audioAttendees, id);
            this.removeElement(this.silentAttendees, id);
        },
        addAudioMember(id) {
            if (this.audioAttendees.indexOf(id) < 0) {
                this.audioAttendees.push(id);
            }
            this.removeElement(this.videoAttendees, id);
            this.removeElement(this.silentAttendees, id);
        },
        addSilentMember(id) {
            if (this.silentAttendees.indexOf(id) < 0) {
                this.silentAttendees.push(id);
            }
            this.removeElement(this.audioAttendees, id);
            this.removeElement(this.videoAttendees, id);
        },
        handleChatSelect(payload = null) {
            if (!payload || "chat-select" !== payload.id) return;
            this.chatSelection = payload.value;
        },
        toggleGridView() {
            this.gridViewIsActive = !this.gridViewIsActive;
        },
        handleUiCancelLayer(event) {
            const element = event.target;

            if (element === undefined) {
                console.log("bad handleUiCancelLayer");
            }

            if (
                !element.classList.contains("ui-navigation-control") &&
                !element.closest(".mg-control-panel")
            ) {
                this.clearActiveUi();
            }
        },
        handleUiDefinitions() {
            const primaryControls = this.$refs["mg-control-panel--primary"];
            let primaryControlsHeight = 80;

            if (primaryControls) {
                primaryControlsHeight = primaryControls.getBoundingClientRect()
                    .height;
            }

            if (this.uiWrapper) {
                this.uiWrapper.style.setProperty(
                    "--mg-control-panel--primary-height",
                    `${primaryControlsHeight}px`
                );
            }
        },
        clearActiveUi() {
            this.activeUiElements = [];
        },
        toggleContentShare() {
            if (!this.allowScreenShare) return;

            if (this.myContentIsSharing) {
                this.stopContentShare();
            } else if (this.contentTile) {
                //FIXME Cannot share while another person is sharing
                const keys = [];
                let p;
                for (p in this.videoFeeds)
                    if (
                        Object.prototype.hasOwnProperty.call(this.videoFeeds, p)
                    )
                        keys.push(p);
                for (let i = 0; i < keys.length; i++) {
                    const info = this.videoFeeds[keys[i]];
                    if (info.isContent) {
                        return;
                    }
                }
            } else {
                this.startContentShare();
            }
        },
        startContentShare() {
            const sharePromise = this.chime.meetingSession.audioVideo.startContentShareFromScreenCapture();
            if (sharePromise instanceof Promise) {
                sharePromise
                    .then(() => {
                        this.myContentIsSharing = true;
                    })
                    .catch(() => {
                        // this is called if the user hits cancel from the screen share dialog
                        this.myContentIsSharing = false;
                    });
            }
        },
        stopContentShare() {
            const stopSharingPromise = this.chime.meetingSession.audioVideo.stopContentShare();
            if (stopSharingPromise instanceof Promise) {
                stopSharingPromise
                    .then(() => {
                        this.myContentIsSharing = false;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                this.myContentIsSharing = false;
            }
        },
        toggleVideo: async function() {
            if (this.cameraOn) {
                try {
                    await this.chime.meetingSession.audioVideo.stopLocalVideoTile();
                    await this.chime.meetingSession.audioVideo.removeLocalVideoTile();
                } catch (error) {
                    console.error("Couldn't stop tile: ", error);
                }
                this.cameraOffSelect = true;
                this.cameraOn = false;
            } else {
                if (Object.keys(this.videoFeeds).length >= this.maxVideoFeeds) {
                    return;
                }
                this.cameraOn = true;
                this.cameraOffSelect = false;

                await this.setVideoInput(this.currentVideoDevice);

                try {
                    await this.chime.meetingSession.audioVideo.startLocalVideoTile();
                } catch (error) {
                    console.error("Couldn't start tile: ", error);
                }
            }
        },
        toggleUiElement(elementClass = "") {
            if (!elementClass) {
                return;
            }
            const myIndex = this.activeUiElements.indexOf(elementClass);

            if (-1 < myIndex) {
                // Hide element
                this.activeUiElements.splice(myIndex, 1);
            } else {
                // Show element
                this.activeUiElements.push(elementClass);
            }
        },
        handleIdleCount() {
            clearTimeout(this.idleTimer);

            if (this.activeUiElements.length) {
                //abort
                return;
            }

            this.idleState = false;

            this.idleTimer = setTimeout(() => {
                this.idleState = true;
            }, this.idleWait);
        },
        handleDeviceSelect(payload) {
            if ("audio_input" === payload.device) {
                this.setAudioInput(payload.value);
            } else if ("audio_output" === payload.device) {
                this.setAudioOutput(payload.value);
            } else if ("video_input" === payload.device) {
                this.setVideoInput(payload.value);
            }
        },
        setAudioInput: async function(value) {
            this.currentAudioInput = value;
            localStorage["preferredAudioInput"] = value;

            try {
                await this.chime.meetingSession.audioVideo.chooseAudioInputDevice(
                    value
                );
            } catch (error) {
                console.error("Could not set audio input: ", value, error);
                return;
            }
        },
        setAudioOutput: async function(value) {
            try {
                await this.chime.meetingSession.audioVideo.chooseAudioOutputDevice(
                    value
                );
            } catch (error) {
                console.error("Could not set audio output: ", value, error);
                return;
            }
            localStorage["preferredAudioOutput"] = value;
            const audioOutputElement = document.getElementById("GrahamBell");
            if (!this.boundAudioElement) {
                try {
                    await this.chime.meetingSession.audioVideo.bindAudioElement(
                        audioOutputElement
                    );
                } catch (error) {
                    console.error(
                        "Unable to bind audio element: ",
                        value,
                        error
                    );
                }
                this.boundAudioElement = true;
            }
        },
        setCurrentVideoDevice(value) {
            this.currentVideoDevice = value;
            localStorage.setItem("preferredVideoInput", value);
        },
        setVideoInput: async function(value) {
            // First handle video quality options
            if (this.isPresentation && this.isMeetingPresenter) {
                try {
                    await this.chime.meetingSession.audioVideo.chooseVideoInputQuality(
                        960,
                        540,
                        60,
                        1400
                    );
                } catch (error) {
                    console.error("WARNING: not setting resolution");
                }
            } else {
                try {
                    await this.chime.meetingSession.audioVideo.chooseVideoInputQuality(
                        320,
                        180,
                        10,
                        600
                    );
                } catch (error) {
                    console.error("WARNING: not setting resolution");
                }
            }

            // Then
            this.setCurrentVideoDevice(value);

            if (value) {
                try {
                    await this.chime.meetingSession.audioVideo
                        .chooseVideoInputDevice(value)
                        .then((response) => {
                            // https://aws.github.io/amazon-chime-sdk-js/enums/devicepermission.html
                            if (3 === response || 2 === response) {
                                this.setCurrentVideoDevice("");
                            }

                            if (3 === response) {
                                alert(siteSystemPermissionError);
                            }

                            if (2 === response) {
                                alert(siteVideoPermissionError);
                            }
                        });
                } catch (error) {
                    console.error(
                        "Unable to select video input: ",
                        value,
                        error
                    );
                    return;
                }
            }
        },
        audioInputsChanged: function(freshInputs) {
            this.audioInputs = freshInputs;
        },
        audioOutputsChanged: function(freshOutputs) {
            this.audioOutputs = freshOutputs;
        },
        videoInputsChanged: function(freshInputs) {
            this.videoInputs = freshInputs;
        },
        setMuted: async function() {
            if (
                !this.chime.meetingSession &&
                !this.chime.meetingSession.audioVideo
            )
                return;

            this.muted = !this.muted;

            if (this.muted) {
                //FIXME and below
                await this.chime.meetingSession.audioVideo.realtimeMuteLocalAudio();
            } else {
                await this.chime.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
            }
        },
        activeSpeakerHandler(speakerInfo) {
            // Content share always overrides the active speaker
            if (
                this.contentTile &&
                this.contentTile.boundVideoElement instanceof HTMLVideoElement
            ) {
                this.currentSpeaker = this.contentTile.boundAttendeeId;
                this.nodeToShow = this.contentTile.boundVideoElement;
            } else if (Array.isArray(speakerInfo) && 1 === speakerInfo.length) {
                this.currentSpeaker = speakerInfo[0];
                const speakerRosterItem = this.roster[this.currentSpeaker];

                if (
                    speakerRosterItem &&
                    speakerRosterItem.video instanceof HTMLVideoElement
                ) {
                    this.nodeToShow = this.roster[this.currentSpeaker].video;
                } else if (this.myTile) {
                    this.nodeToShow = this.myTile.boundVideoElement;
                }
            }

            this.currentSpeakerEl = this.nodeToShow;
        },
        getAttendeeData(attendeeId, isSelf = false) {
            const options = { id: attendeeId, isDemoData: false };

            return new Promise((resolve, reject) => {
                if (!attendeeId) {
                    return reject("no attendeeId");
                }

                attendeeStore
                    .getAttendee(options)
                    .then(() => {
                        try {
                            if (undefined === this.queriedAttendee) {
                                return;
                            }
                            const item = JSON.parse(
                                JSON.stringify(this.queriedAttendee)
                            );
                            item["displayName"] = this.formatName(item);
                            this.attendeeInfo[attendeeId] = item;
                            if (isSelf) {
                                this.selfAttendeeInfo = item;
                                this.displayName = item["displayName"];
                            }
                            for (let i = 0; i < this.messages.length; i++) {
                                const message = this.messages[i];
                                if (message && message.id == attendeeId) {
                                    message.name = item["displayName"];
                                }
                            }
                            for (const key in this.roster) {
                                const who = this.roster[key];
                                if (
                                    who.externalUserId &&
                                    who.externalUserId.split(":")[0] ==
                                        attendeeId
                                ) {
                                    who.info = item;
                                }
                            }

                            return resolve();
                        } catch (error) {
                            console.error("ERROR in attendee handler: ", error);
                        }
                    })
                    .catch((error) => {
                        return reject(error);
                    });
            });
        },
        formatName(info) {
            if (info === undefined) {
                return this.unknownName;
            }
            if (info["firstName"] && info["lastName"])
                return info["firstName"] + " " + info["lastName"];
            else if (info["firstName"]) return info["firstName"];
            else return info["lastName"] ? info["lastName"] : "Unknown";
        },
        setupAttendeePresenceHandler() {
            const presenceHandler = async (
                attendeeId,
                present,
                externalUserId,
                dropped,
                posInFrame
            ) => {
                try {
                    const selfAttendeeId = this.chime.meetingSession
                        .configuration.credentials.attendeeId;
                    let isContent = false;

                    if (attendeeId.indexOf("#") >= 0) {
                        attendeeId = attendeeId.split("#")[0];
                        isContent = true;
                        if (!present && this.myContentIsSharing)
                            this.toggleContentShare();
                        return;
                    }

                    if (!present) {
                        this.removeElement(this.videoAttendees, attendeeId);
                        this.removeElement(this.audioAttendees, attendeeId);
                        this.removeElement(this.silentAttendees, attendeeId);
                        this.$delete(this.roster, attendeeId);
                        return;
                    }

                    if (this.attendeeInfo[attendeeId] === undefined) {
                        try {
                            await this.getAttendeeData(
                                externalUserId.split(":")[0],
                                attendeeId == selfAttendeeId
                            );
                        } catch (error) {
                            console.error(error);
                        }
                    }

                    // roster: attendeeId -> {
                    //             externalUserId,
                    //             info
                    //             video   HTMLVideoElement (Bound if active)
                    // }
                    // TODO dont add roster item if externalUserId already exists in roster

                    if (!this.roster[attendeeId]) {
                        if (
                            !this.isPresentation ||
                            this.attendeeIsPresenter(
                                externalUserId.split(":")[0]
                            )
                        ) {
                            this.addAudioMember(attendeeId);
                        } else {
                            this.addSilentMember(attendeeId);
                        }
                        this.$set(this.roster, attendeeId, {});
                    }

                    if (!this.roster[attendeeId].externalUserId) {
                        this.$set(
                            this.roster[attendeeId],
                            "externalUserId",
                            externalUserId
                        );
                    }

                    if (!this.roster[attendeeId].info) {
                        this.$set(
                            this.roster[attendeeId],
                            "info",
                            this.attendeeInfo[externalUserId.split(":")[0]]
                        );
                    }

                    if (!this.roster[attendeeId].video) {
                        this.$set(
                            this.roster[attendeeId],
                            "video",
                            this.getStaticElement(attendeeId)
                        );
                    }

                    this.chime.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(
                        attendeeId,
                        async (attendeeId, volume, muted, signalStrength) => {
                            try {
                                if (!this.roster[attendeeId]) {
                                    return;
                                }
                                if (volume !== null) {
                                    this.$set(
                                        this.roster[attendeeId],
                                        "volume",
                                        Math.round(volume * 100)
                                    );
                                }
                                if (muted !== null) {
                                    this.$set(
                                        this.roster[attendeeId],
                                        "muted",
                                        muted
                                    );
                                }
                                if (signalStrength !== null) {
                                    this.$set(
                                        this.roster[attendeeId],
                                        "signalStrength",
                                        Math.round(signalStrength * 100)
                                    );
                                }
                            } catch (error) {
                                console.error(
                                    "Error in volume callback: ",
                                    error
                                );
                            }
                        }
                    );
                } catch (error) {
                    console.error("ERROR in presence handler: ", error);
                }
            };
            this.chime.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
                presenceHandler
            );
        },
        initializeVideo: async function() {
            let videoDevice = localStorage["preferredVideoInput"];

            if (
                !videoDevice &&
                Array.isArray(this.videoInputs) &&
                this.videoInputs[0]?.deviceId
            ) {
                videoDevice = this.videoInputs[0].deviceId;
            }

            return await this.setVideoInput(videoDevice);
        },
        setupChime: async function() {
            // setupCanUnmuteHandler FIXME

            this.messageTopics.forEach((topic) => {
                this.chime.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(
                    topic,
                    (dataMessage) => {
                        this.dataMessageHandler(dataMessage);
                    }
                );
            });

            if (!this.isPresentation || this.isPresenterOrSuperUser) {
                await this.initializeVideo();
            }

            let audioInputDevice = localStorage["preferredAudioInput"];
            if (audioInputDevice === undefined) {
                // FIXME: or is deleted...
                audioInputDevice = this.audioInputs[0].deviceId;
            }
            await this.setAudioInput(audioInputDevice);
            await this.setMuted();

            let audioOutputDevice = localStorage["preferredAudioOutput"];
            if (audioOutputDevice === undefined) {
                // FIXME: or is deleted...
                audioOutputDevice =
                    this.audioOutputs.length == 0
                        ? undefined
                        : this.audioOutputs[0].deviceId;
            }

            await this.setAudioOutput(audioOutputDevice);

            const observer = {
                // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
                videoTileDidUpdate: async (tileState) => {
                    try {
                        const rosterItemToAdd = {};

                        if (!tileState.boundAttendeeId) {
                            return;
                        }
                        if (tileState.active) {
                            this.addVideoMember(tileState.boundAttendeeId);
                        } else {
                            this.addAudioMember(tileState.boundAttendeeId);
                        }
                        if (tileState.localTile && !tileState.isContent) {
                            this.myTile = tileState;
                        }
                        const selfAttendeeId = this.chime.meetingSession
                            .configuration.credentials.attendeeId;
                        if (
                            tileState.boundAttendeeId.split("#")[0] ==
                                selfAttendeeId &&
                            tileState.isContent
                        ) {
                            // don't bind one's own content
                            return;
                        }

                        if (this.currentSpeaker === undefined) {
                            this.currentSpeaker = tileState.boundAttendeeId;
                        }
                        let newElement = tileState.boundVideoElement;
                        if (!(newElement instanceof HTMLVideoElement)) {
                            if (tileState.isContent) {
                                newElement = undefined;
                            }

                            if (this.roster[tileState.boundAttendeeId]) {
                                newElement = this.roster[
                                    tileState.boundAttendeeId
                                ].video;
                            }

                            if (!(newElement instanceof HTMLVideoElement)) {
                                newElement = document.createElement("video");
                                newElement["id"] =
                                    "id-" + tileState.boundAttendeeId;
                                newElement["autoplay"] = "true";
                                newElement.className = this.videoElementClasses;
                            }

                            if (newElement instanceof HTMLVideoElement) {
                                try {
                                    await this.chime.meetingSession.audioVideo.bindVideoElement(
                                        tileState.tileId,
                                        newElement
                                    );
                                } catch (error) {
                                    console.log(
                                        "Can't bind video element: ",
                                        tileState,
                                        newElement
                                    );
                                }
                            }
                        }

                        if (tileState.isContent && this.contentTile === null) {
                            newElement.className =
                                "absolute top-0 left-0 w-full h-full object-fit";
                            this.contentTile = tileState;
                            this.contentTile.boundVideoElement = newElement;

                            this.currentSpeaker = this.contentTile.boundAttendeeId;
                            this.nodeToShow = this.currentSpeakerEl = newElement;

                            this.gridViewWasActive = this.gridViewIsActive;
                            this.gridViewIsActive = false;
                        }

                        rosterItemToAdd.video = newElement;

                        const upPromise = this.chime.meetingSession.audioVideo.unpauseVideoTile(
                            tileState.tileId
                        );
                        if (upPromise !== undefined) upPromise.then().catch();

                        this.$set(this.videoFeeds, tileState.tileId, tileState);
                        if (
                            Object.keys(this.videoFeeds).length >=
                            this.maxVideoFeeds
                        ) {
                            this.canAddVideo = false;
                        }

                        // Finally $set the roster item
                        if (
                            !this.roster[tileState.boundAttendeeId] &&
                            !tileState.isContent
                        ) {
                            this.addVideoMember(tileState.boundAttendeeId);
                            this.$set(
                                this.roster,
                                tileState.boundAttendeeId,
                                rosterItemToAdd
                            );
                        }
                    } catch (error) {
                        console.error("Error handling video tile", error);
                    }
                },
                videoTileWasRemoved: (tileId) => {
                    try {
                        if (
                            this.contentTile &&
                            this.contentTile.tileId == tileId
                        ) {
                            // dont delete data() item - reset to original value
                            this.contentTile = null;

                            if ("string" === typeof this.currentSpeaker) {
                                this.currentSpeaker = this.currentSpeaker.split(
                                    "#content"
                                )[0];
                            }

                            if (
                                !this.gridViewIsActive &&
                                this.currentSpeakerData &&
                                Object.keys(this.currentSpeakerData).length &&
                                this.currentSpeakerData.video instanceof
                                    HTMLVideoElement
                            ) {
                                this.currentSpeakerEl = this.nodeToShow = this.currentSpeakerData.video;
                            } else if (this.myTile) {
                                this.currentSpeakerEl = this.nodeToShow = this.myTile.boundVideoElement;
                            }
                            this.gridViewIsActive = this.gridViewWasActive;
                        }

                        if (!this.videoFeeds[tileId]) return;
                        const aid = this.videoFeeds[tileId].boundAttendeeId;
                        const rosterItem = this.roster[aid];
                        let externalUserId = "";

                        if (
                            rosterItem &&
                            "string" === typeof rosterItem.externalUserId
                        ) {
                            externalUserId = rosterItem["externalUserId"].split(
                                ":"
                            )[0];
                        }

                        if (
                            this.isPresentation &&
                            !this.attendeeIsPresenter(externalUserId)
                        ) {
                            this.addSilentMember(aid);
                        } else {
                            this.addAudioMember(aid);
                        }

                        this.$delete(this.videoFeeds, tileId);

                        if (
                            Object.keys(this.videoFeeds).length <
                            this.maxVideoFeeds
                        ) {
                            if (this.isPresentation) {
                                this.canAddVideo =
                                    this.isPresenterOrSuperUser ||
                                    this.isPromoted;
                            } else {
                                this.canAddVideo = true;
                            }
                        }
                    } catch (error) {
                        console.error("Error removing video tile: ", error);
                    }
                },
                videoAvailabilityDidChange: (availability) => {
                    try {
                        this.canStartLocalVideo =
                            availability.canStartLocalVideo;
                        if (this.canStartLocalVideo) {
                            if (!this.cameraOffSelect) {
                                this.cameraOn = true;
                                this.chime.meetingSession.audioVideo.startLocalVideoTile();
                            }
                        }
                        this.remoteVideoAvailable =
                            availability.remoteVideoAvailable;
                    } catch (error) {
                        console.error(
                            "Error changing video availability: ",
                            error
                        );
                    }
                },
                videoSendDidBecomeUnavailable: () => {
                    this.cameraOn = false;
                    console.log("videoSendDidBecomeUnavailable");
                }
            };

            this.chime.meetingSession.audioVideo.addObserver(observer);
            this.chime.meetingSession.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(
                (muted) => {
                    this.muted = muted;
                }
            );
            this.setupAttendeePresenceHandler();
            this.chime.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(
                new DefaultActiveSpeakerPolicy(),
                this.activeSpeakerHandler
            );

            await this.chime.meetingSession.audioVideo.start();

            //this.chime.meetingSession.audioVideo.startLocalVideoTile();

            // Finally, do this last
            this.meetingHasConnection = true;
        },
        getStaticElement(attendeeId) {
            const newElement = document.createElement("video");
            newElement["id"] = "id-" + attendeeId;
            newElement.className = this.videoElementClasses;
            return newElement;
        },
        cleanupChime() {
            const promises = [];

            this.roster = {};

            if (this?.chime?.meetingSession) {
                const meetingId = this.meetingId;
                const selfId = this.getSelfAttendeeId();

                if (this.myContentIsSharing) {
                    this.stopContentShare();
                }

                const chooseVideoInputDevicePromise = this.chime.meetingSession.audioVideo.chooseVideoInputDevice(
                    null
                );
                const audioVideoPromise = this.chime.meetingSession.audioVideo.stop();

                promises.push(chooseVideoInputDevicePromise);
                promises.push(audioVideoPromise);

                if (meetingId && selfId) {
                    const payload = {
                        meetingId: meetingId,
                        attendeeId: selfId
                    };
                    const leaveMeetingRoomPromise = appointmentsStore.leaveMeetingRoom(
                        payload
                    );

                    promises.push(leaveMeetingRoomPromise);
                }

                // https://aws.github.io/amazon-chime-sdk-js/classes/defaultmeetingsession.html#destroy
                if ("destroy" in this.chime.meetingSession) {
                    const destroyPromise = this.chime.meetingSession.destroy();
                    promises.push(destroyPromise);
                }
            }

            return Promise.allSettled(promises).then((result) => {
                this.chime.meetingSession = null;
                return result;
            });
        },
        async leaveMeeting() {
            this.isLoading = true;

            // logging
            if (this.meetingIsOver) {
                this.logKickedOutEvent();
            }

            await this.cleanupChime();

            const redirectTo = this.exitTo;
            this.$router.push(redirectTo);

            this.isLoading = false;
        },
        goToSettingsPage(page) {
            this.settingsPage = page;
        },
        goToSecondarySettingsPage(page) {
            this.secondarySettingsPage = page;
        },
        sendMessageToUser(msg, target) {
            this.sendMessage(msg, "direct/" + target);
        },
        sendMessage(msg, topic = "chat") {
            new AsyncScheduler().start(() => {
                msg = msg.trim();

                if (!msg) {
                    return;
                }

                msg = this.MgSanitize(msg);

                this.chime.meetingSession.audioVideo.realtimeSendDataMessage(
                    topic,
                    msg,
                    this.DATA_MESSAGE_LIFETIME_MS
                );
                // echo the message to the handler
                this.dataMessageHandler(
                    new DataMessage(
                        Date.now(),
                        topic,
                        new TextEncoder().encode(msg),
                        this.chime.meetingSession.configuration.credentials.attendeeId,
                        this.chime.meetingSession.configuration.credentials.externalUserId
                    )
                );
            });
        },
        async dataMessageHandler(dataMessage) {
            if (!dataMessage.throttled) {
                //const isSelf = dataMessage.senderAttendeeId === this.meetingSession.configuration.credentials.attendeeId;
                /* if (
                    dataMessage.timestampMs <= this.lastReceivedMessageTimestamp
                ) {
                    return;
                }*/
                const selfAttendeeId = this.chime.meetingSession.configuration
                    .credentials.attendeeId;
                const attendeeId = dataMessage.senderExternalUserId.split(
                    ":"
                )[0];
                if (attendeeId === undefined) {
                    try {
                        await this.getAttendeeData(
                            attendeeId,
                            dataMessage.senderAttendeeId ==
                                this.chime.meetingSession.configuration
                                    .credentials.attendeeId
                        );
                    } catch (error) {
                        console.error(error);
                    }
                }

                // TODO
                // we'll need to subscribe to topics labeled as: "direct/" + id

                if (
                    dataMessage.topic == "chat" ||
                    dataMessage.topic == "direct/" + selfAttendeeId
                ) {
                    this.lastReceivedMessageTimestamp = dataMessage.timestampMs;
                    this.lastMessageSender = dataMessage.senderAttendeeId;
                    const toFormat = this.attendeeInfo[attendeeId];

                    const message = {
                        name: this.formatName(toFormat),
                        message: dataMessage.text(),
                        time: new Date(dataMessage.timestampMs).toISOString(),
                        id: attendeeId
                    };

                    if (dataMessage.text()) {
                        this.messages.push(message);
                    }
                } else if (dataMessage.topic == "raisehand") {
                    this.handleRaiseHand(dataMessage.senderAttendeeId);
                } else if (dataMessage.topic == "lowerhand") {
                    this.handleLowerHand(dataMessage.senderAttendeeId);
                } else if (dataMessage.topic == "promote") {
                    /* FIXME: Verify that the sender attendee is a presenter */
                    this.handlePromoteAttendee(dataMessage.text());
                } else if (dataMessage.topic == "demote") {
                    /* FIXME: Verify that the sender attendee is a presenter */
                    this.handleDemoteAttendee(dataMessage.text());
                }
            } else {
                this.log("Message is throttled. Please resend");
            }
        },
        toggleRaiseHand() {
            this.handIsRaised = !this.handIsRaised;

            if (this.handIsRaised) {
                this.requestRaiseHand();
            } else {
                this.requestLowerHand();
            }
        },
        requestRaiseHand() {
            this.sendMessage(this.getSelfAttendeeId(), "raisehand");
        },
        handleRaiseHand(senderAttendeeId) {
            const timeStamp = new Date().toISOString();
            const itemToAdd = `${senderAttendeeId}@${timeStamp}`;
            if (!this.raisedHands.includes(itemToAdd)) {
                this.raisedHands.push(itemToAdd);
            }
        },
        requestLowerHand() {
            this.sendMessage(this.getSelfAttendeeId(), "lowerhand");
        },
        handleLowerHand(senderAttendeeId) {
            const toRemove = this.raisedHands.find((item) => {
                return item.includes(senderAttendeeId);
            });

            this.handleDemoteAttendee(senderAttendeeId);
            this.removeElement(this.raisedHands, toRemove);
        },
        /* This should be called when a Presenter clicks on a button to promote someone */
        requestPromotion(attendeeId) {
            this.sendMessage(attendeeId, "promote");
        },
        /* This should be called when we get the promotion datamessage only */
        async handlePromoteAttendee(promoteAttendeeId) {
            const selfAttendeeId = this.chime.meetingSession.configuration
                .credentials.attendeeId;
            this.promotedFolks.push(promoteAttendeeId);
            if (promoteAttendeeId == selfAttendeeId) {
                // init video before adding tile
                await this.initializeVideo();

                this.isPromoted = true;

                // Unmute when promoted; they should be able to mute if
                // required
                if (this.muted) {
                    this.setMuted();
                }
            }
        },
        /* This should be called when a Presenter clicks on a button to demote someone */
        requestDemoteAttendee(demoteAttendeeId) {
            this.sendMessage(demoteAttendeeId, "demote");
        },
        /* This should be called when we get the demotion dataMessage only */
        handleDemoteAttendee(demoteAttendeeId) {
            const selfAttendeeId = this.chime.meetingSession.configuration
                .credentials.attendeeId;
            this.removeElement(this.promotedFolks, demoteAttendeeId);
            if (demoteAttendeeId == selfAttendeeId) {
                this.isPromoted = false;
                // Mute when promoted; they should be able to mute if
                // required
                if (!this.muted) {
                    this.setMuted();
                }
                if (this.cameraOn) {
                    this.toggleVideo();
                }
            }
        },

        sendChatMessage() {
            this.sendMessage(
                linkifyHtml(this.message, {
                    attributes: {
                        rel: "noopener noreferrer"
                    }
                })
            );

            this.message = "";
            this.$nextTick(() => {
                const chatScroll = this.$refs["chat-scroll"];
                if (chatScroll) {
                    // scroll to bottom to see submitted message
                    chatScroll.scrollTo(null, chatScroll.scrollHeight);
                }
            });
        },
        setupDeviceLabelTrigger() {
            this.chime.meetingSession.audioVideo.setDeviceLabelTrigger(
                async () => {
                    try {
                        const stream = await navigator.mediaDevices.getUserMedia(
                            {
                                audio: true,
                                video: true
                            }
                        );

                        return stream;
                    } catch (error) {
                        if (
                            error.message.includes("Permission denied") ||
                            error.message.includes("user denied permission")
                        ) {
                            alert(siteVideoPermissionError);
                        }

                        console.error("Unable to getUserMedia: ", error);
                    }
                }
            );
        },
        handleControlPermissions() {
            if (this.isPresentation) {
                this.canAddAudio =
                    this.isPresenterOrSuperUser || this.isPromoted;
                this.canAddVideo =
                    this.isPresenterOrSuperUser || this.isPromoted;
            } else {
                this.canAddVideo = true;
                this.canAddAudio = true;
            }
        },
        async init() {
            await this.initStep1();

            if (this.hasMultiMediaOptions) {
                this.handleInitStep2();
            } else {
                this.showInputsModal = true;
            }
        },
        async initStep1() {
            const sessionId = this.$route.params.id;
            // const userId = this.$store.getters.user.id;

            this.setInitialBrandColor();

            if (this.$store.getters.user === undefined) {
                console.log("Bad USER");
            }

            this.chime = Object();

            this.chime.logger = new ConsoleLogger(
                "ChimeMeetingLogs",
                LogLevel.WARN
            );
            this.chime.deviceController = new DefaultDeviceController(
                this.chime.logger
            );

            let attendeePromise;
            let meetingRoomPromise;

            try {
                // Fetch in Parallel to speed things up
                attendeePromise = this.getAttendeeData(
                    this.$store.getters.user.id,
                    true
                );
                meetingRoomPromise = appointmentsStore.getMeetingRoom(
                    sessionId
                );

                // do something with results.
                await attendeePromise; //nothing to do with this.
                const meetingRoomData = await meetingRoomPromise.catch(
                    (error) => {
                        if (404 === error?.response?.status) {
                            this.$router.push({ name: "NotFound" });
                        } else if ("string" === typeof error.response.data) {
                            this.errorMessage = error.response.data;
                        }
                    }
                );

                this.meetingApiData = meetingRoomData?.data || {};

                // Capacity notice
                const networkingTableSize = this.meetingApiData
                    ?.networkingTableSize;
                const currentAttendeeCount = this.meetingApiData
                    ?.currentAttendeeCount;

                if (
                    Boolean(networkingTableSize > 0) &&
                    Boolean(currentAttendeeCount > 0)
                ) {
                    if (currentAttendeeCount >= networkingTableSize) {
                        this.errorMessage =
                            "Room is currently at capacity. Please check back later.";
                    }
                }
            } catch (error) {
                console.error(error);
                // TODO might make sense to throw here. Not sure.
                // if( !this.meetingApiData ){
                // 	throw new Error('Failed to get meeting data')
                // }
            }

            this.$rollbar.warning(
                `Enter meeting, ID ${this.$store.getters.user.id},
                MeetingID: ${this.meetingApiData["meeting"]["MeetingId"]},
                AttendeeID: ${this.meetingApiData["attendee"]["AttendeeId"]}`
            );
            this.meetingId = this.meetingApiData["meeting"]["MeetingId"];

            this.chime.attendee = this.meetingApiData["attendee"];
            this.chime.meeting = this.meetingApiData["meeting"];

            this.chime.configuration = new MeetingSessionConfiguration(
                this.chime.meeting,
                this.chime.attendee
            );
            this.chime.configuration.enableUnifiedPlanForChromiumBasedBrowsers = true;
            this.chime.configuration.enableWebAudio = false;
            this.chime.meetingSession = new DefaultMeetingSession(
                this.chime.configuration,
                this.chime.logger,
                this.chime.deviceController
            );

            this.chime.deviceController.addDeviceChangeObserver(this);
            // FIXME: setupDeviceLabelTrigger
            this.setupDeviceLabelTrigger();
            await this.populateAllDeviceLists();
        },
        async initStep2() {
            await this.setupChime();

            // decide muted
            if (this.isPresentation) {
                this.muted = !this.isPresenterOrSuperUser || !this.isPromoted;
                if (this.muted) {
                    this.chime.meetingSession.audioVideo.realtimeMuteLocalAudio();
                }
            }

            this.handleControlPermissions();

            if (this.meetingHasConnection) {
                this.handleMeetingTime();
                this.handleLogUsage();
            }

            this.handleMeetingPreferences();
        },
        async handleInitStep2() {
            await this.initStep2().catch(() => {
                console.error("Init step 2 failed.");
            });

            this.isLoading = false;
        },
        async populateAllDeviceLists() {
            this.audioInputs = await this.chime.meetingSession.audioVideo.listAudioInputDevices();
            this.audioOutputs = await this.chime.meetingSession.audioVideo.listAudioOutputDevices();
            this.videoInputs = await this.chime.meetingSession.audioVideo.listVideoInputDevices();
        },
        countMeetingTime() {
            if (0 < this.meetingSecondsInitial) {
                this.secondsCountDown = this.meetingSecondsInitial;
            }

            this.meetingSecondsDisplay = "00";

            function pad(val) {
                const valString = val + "";
                if (valString.length < 2) {
                    return "0" + valString;
                } else {
                    return valString;
                }
            }

            const setTime = () => {
                this.meetingMinutesDisplay = pad(
                    parseInt(this.secondsCountDown / 60)
                );
                this.meetingSecondsDisplay = pad(this.secondsCountDown % 60);

                this.setMeetingTimeRemaining();

                if (
                    this.extraMinutesActive &&
                    (this.secondsCountDown <= 0 ||
                        this.extraSecondsOvertime > this.timeRemaining)
                ) {
                    clearInterval(this.meetingCount);
                    this.meetingMinutesDisplay == "00";
                    this.meetingSecondsDisplay == "00";
                    this.meetingIsOver = true;
                } else if (this.secondsCountDown <= 0) {
                    this.secondsCountDown = this.extraSeconds;
                    this.extraMinutesActive = true;
                } else if (0 <= this.timeRemaining) {
                    this.secondsCountDown = this.timeRemaining;
                } else {
                    --this.secondsCountDown;
                }
            };

            this.meetingCount = setInterval(setTime, 1000);
        },
        async handleLogUsage() {
            this.logInterval = window.setInterval(() => {
                const logData = {
                    type: "MeetingUsage",
                    uid: this.$store.getters.user.id,
                    attendeeID: this.chime.attendee,
                    meetingID: this.chime.meeting["MeetingId"],
                    sessionID: this.$route.params.id
                };

                logStore.appendLogEntry(logData).catch((error) => {
                    console.error(
                        "Error trying to log: ",
                        logData,
                        error,
                        error.stack
                    );
                });
            }, 60 * 1000);
        },
        handleMeetingTime() {
            // first set meeting seconds
            let timeToSet = 0;

            if (this.meetingData && this.meetingData.startTimeISO) {
                this.setMeetingTimeRemaining();
                timeToSet = this.timeRemaining;
            } else {
                timeToSet = DEFAULT_MEETING_DURATION;
            }

            this.meetingSecondsInitial = timeToSet;

            // then start countdown
            this.countMeetingTime();
        },
        handleTertiaryPanel(pageWanted = null) {
            const tertiaryPanel = "mg-control-panel--tertiary";

            if (this.isTertiaryPanelActive) {
                if (pageWanted === this.secondarySettingsPage) {
                    this.toggleUiElement(tertiaryPanel);
                } else {
                    // this else is intentional - nothing to do at this point
                }
            } else {
                this.toggleUiElement(tertiaryPanel);
            }

            this.goToSecondarySettingsPage(pageWanted);
        },
        isAttendeePromoted(id = "") {
            return Boolean(this.promotedFolks.includes(id));
        },
        handleSpeakerElement(element) {
            const el = element || this.$refs["mg-speaker-element"];

            if (!el) return;

            const currentSpeakerData = this.currentSpeakerData;
            const currentSpeakerEl = this.currentSpeakerEl;
            let video = null;

            if (currentSpeakerEl) {
                video = currentSpeakerEl;
            } else if (
                currentSpeakerData &&
                Object.keys(currentSpeakerData).length &&
                currentSpeakerData.video instanceof HTMLVideoElement
            ) {
                video = currentSpeakerData.video;
            } else {
                return;
            }

            if (video instanceof HTMLVideoElement) {
                el.innerHTML = "";
                el.appendChild(video);
                const playPromise = video.play();
                if (playPromise instanceof Promise) {
                    playPromise
                        .then(() => {
                            // n/a
                        })
                        .catch(() => {
                            // n/a
                        });
                }
            }
        },
        handleBuefyModalFixes(el) {
            a11yFixBuefyModalAriaAttrs(el);
        },
        addRosterItem(name) {
            // this is here for debugging
            const rosteritemId = new Date().toISOString();
            const rosteritem = {
                video: {},
                externalUserId:
                    "34A13D00000003:fa7d40bb-e37e-4899-8be5-db76f3f40ecf",
                info: {
                    companyId: "2BD50001D2CB",
                    company: {},
                    lastName: "Jefferson",
                    companyName: "K-VA-T Food Stores, Inc.",
                    attendeeId: "34A13D00000003",
                    prefix: "Mr.",
                    firstName: "Thomas",
                    title: "President, USA",
                    displayName: "Thomas Jefferson"
                }
            };
            rosteritem.info.firstName = name || "Thomas";
            this.$set(this.roster, rosteritemId, rosteritem);
            this.videoAttendees.push(rosteritemId);
        }
    }
};
</script>

<style lang="scss">
@import "../styles/views/meeting";
</style>
